import jwtDecode from 'jwt-decode'
import { readFromCache, getMeFromCache, writeToCache } from '../apollo/cacheHelper'
import UrlHelper from './urlHelper'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import { isString } from 'lodash'
import { cacheDefaults } from '../apollo/cacheDefaults'
import statusHelper from './statusHelper'
import eventTypes from './eventTypeHelper'
import { updateUserContentIds } from './userHelper'
import { contentLocations } from './contentHelper'
import { whitelistedAccounts, environment } from '../config'
let apolloClient = {}
let analytics = window.rudderanalytics

const analyticsBasicProperties = {
  browserCodeName: navigator.appCodeName,
  height: window.screen.height,
  width: window.screen.width,
  deviceAgent: navigator.userAgent,
  browserName: navigator.appName,
  browserVersion: navigator.appVersion,
  cookieEnabled: navigator.cookieEnabled,
  docURI: document.documentURI,
  language: navigator.language,
  online: navigator.onLine,
  platform: 'WEB',
  product: navigator.product,
  productSub: navigator.productSub,
  domain: document.domain
}

const eventsToTrackForRecentContent = [
  eventTypes.CONTENT_OPENED,
  eventTypes.CONTENT_LIKED,
  eventTypes.CONTENT_UNLIKE,
  eventTypes.CONTENT_SHARED,
  eventTypes.CONTENT_SAVED_OFFLINE,
  eventTypes.CONTENT_UPDATED,
  eventTypes.COMMENT_CREATED,
  eventTypes.COMMENT_REMOVED,
  eventTypes.COMMENT_LIKED,
  eventTypes.COMMENT_UNLIKED,
  eventTypes.COMMENT_UPDATED,
  eventTypes.VIDEO_PLAYED,
  eventTypes.AUDIO_PLAYED,
  eventTypes.SEARCH_ITEM_CLICKED,
  eventTypes.QUICK_LINK_CLICKED
]

export const setApolloClientForAnalytics = (createdClient) => {
  apolloClient = createdClient
}

const isLoggedIn = async () => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    return true
  }
  return false
}

const getActiveCfp = () => {
  const activeCfpId = readFromCache(apolloClient, ['activeCfp'])
  return activeCfpId.activeCfp && activeCfpId.activeCfp._id
}

const getAppProfileAnalyticProperties = (properties) => {
  try {
    const subDomain = UrlHelper.getSubDomain()
    const user = getMeFromCache(apolloClient).getMeRequest || properties
    const membership = user.memberships[0]
    const account = membership.account
    const userAnalyticsEnabled = account.userAnalyticsEnabled
    const accountName = account.name
    const appProfileId = getActiveCfp()
    const appProfileName = membership.contentFeedProfiles.filter(cfp => cfp._id === appProfileId).map(profile => profile.name)[0]
    const currentCfp = user.memberships[0].contentFeedProfiles.filter(cfp => cfp._id === appProfileId)[0]
    const appProfileGroupIds = currentCfp ? membership.groups.filter(value => currentCfp.groups.indexOf(value) !== -1) : membership.groups
    const appProfileGroupNames = currentCfp ? membership.groupObjects.filter(group => currentCfp.groups.indexOf(group._id) !== -1).map(group => group.name) : user.memberships[0].groupObjects.map(group => group.name)

    const userName = userAnalyticsEnabled ? user.name : null
    const userEmail = userAnalyticsEnabled ? user.email : null
    return {
      account: account._id,
      accountName,
      subDomain,
      appProfileGroupIds,
      appProfileGroupNames,
      appProfileId,
      appProfileName,
      userName,
      userEmail,
      user: userAnalyticsEnabled ? user._id : user._id + account._id,
      userAnalyticsEnabled,
      dateTime: Date.now(),
      appProfileIds: appProfileId
    }
  } catch (error) {
    console.error('error getAppProfileAnalyticProperties', error)
  }
}

export const segmentTrackEvent = async (eventType, properties) => {
  try {
    let props
    const accessToken = localStorage.getItem('accessToken')
    const groupsAnalyticsEnabled = jwtDecode(accessToken).groupsAnalyticsEnabled
    const account = properties.account
    const userId = properties.user
    if (properties.userAnalyticsEnabled && !groupsAnalyticsEnabled) {
      props = { ...analyticsBasicProperties, ...properties, userId }
    } else {
      props = { ...analyticsBasicProperties, ...properties, userId: userId + account }
      props.user = userId + account
      props.userEmail = null
      props.userName = null
    }
    // analytics.track(eventType, props)
    analytics = window.rudderanalytics
    analytics.track(eventType, props)
  } catch (error) {
    console.error('error in segmentTrackEvent', error)
  }
}

export const segmentIdentifyEvent = async (me) => {
  const accessToken = localStorage.getItem('accessToken')
  const decodedAccessToken = jwtDecode(accessToken)
  const adminType = decodedAccessToken.adminType
  const appProfileId = getActiveCfp()

  analytics = window.rudderanalytics
  const registrationDateObj = new Date(me.memberships[0].registrationDate)
  await analytics.identify(me._id, {
    name: me.name,
    nameForSearch: me.name.toLowerCase(),
    email: me.email,
    account: me.memberships[0].account._id,
    adminType,
    groupIds: me.memberships[0].groups,
    appProfileIds: appProfileId,
    registrationCode: me.memberships[0].registrationCode,
    registrationDate: registrationDateObj,
    provider: me.provider,
    _user: me._id,
    userid: me._id,
    appProfileId
  })
}

export const trackCalendarEvents = async (properties, contentLocation) => {
  properties.contentLocation = contentLocation
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes.CALENDAR_DAY_OPENED, allDefaultProperties)
}

export const trackUpcomingNavigationLinks = async (properties, eventType) => {
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventType, allDefaultProperties)
}

export const trackUpcomingEvents = async (properties, eventType) => {
  properties.contentLocation = contentLocations.UPCOMING_EVENTS
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventType, allDefaultProperties)
}

export const trackEventsAndUserActivity = async (eventType, properties, client) => {
  if (eventsToTrackForRecentContent.includes(eventType)) {
    if (client) {
      updateUserContentIds(properties, client)
    }
  }

  !isString(Cookies.get('sessionsId')) && Cookies.set('sessionsId', uuidv4())

  const accessToken = localStorage.getItem('accessToken')
  const sessionsId = Cookies.get('sessionsId')
  const decodedAccessToken = jwtDecode(accessToken)
  const adminType = decodedAccessToken.adminType
  const loggedIn = await isLoggedIn()
  // Temporary check to prevent analytics api calls for lower environments except for whitelisted accountss.
  if (environment !== 'production') {
    console.log(whitelistedAccounts)
    if (!whitelistedAccounts.includes(decodedAccessToken.account)) {
      return
    }
  }
  // TO DO -- Implement sessionsId (write and read from cache)
  if (loggedIn) {
    const appProfileAnalyticProperties = getAppProfileAnalyticProperties(properties)
    await segmentTrackEvent(eventType, { ...properties, ...appProfileAnalyticProperties, sessionsId })
    await segmentTrackEvent(eventTypes.USER_ACTIVITY, { eventType, ...appProfileAnalyticProperties, adminType, sessionsId })
  }
}

const getAllAnalyticProperties = async (content) => {
  const loggedIn = await isLoggedIn()
  if (loggedIn) {
    const appProfileAnalyticProperties = getAppProfileAnalyticProperties()
    const ContentAnalyticProperties = contentPropsTransform(content)
    return { ...appProfileAnalyticProperties, ...ContentAnalyticProperties }
  } else {
    return null
  }
}

const contentPropsTransform = (props) => {
  const user = getMeFromCache(apolloClient).getMeRequest
  const appProfileId = getActiveCfp()
  const currentCfp = user.memberships[0].contentFeedProfiles.filter(cfp => cfp._id === appProfileId)[0]
  const membershipGroups = user.memberships[0].groups
  const allGroups = [...membershipGroups]
  allGroups.push(currentCfp.everyoneGroup)
  const contentUserAccessGroupIds = props.accessGroups ? allGroups.filter(value => props.accessGroups.indexOf(value) !== -1) : allGroups
  const objectToReturn = {
    contentAccessGroupIds: props.accessGroups,
    // contentAccessGroupNames: props.accessGroupObjects,
    contentCommentsEnabled: props.commentsEnabled,
    contentCreatedDate: props.created,
    contentDisplayThumbnail: props.displayThumbnail,
    contentFolderId: props.contentFolder,
    // contentFolderName: props.contentFolderName,
    contentId: props._id,
    contentIncludeInFeed: props.includeInFeed,
    contentNotify: props.notify,
    contentOwner: props.owner,
    contentOwnerName: props.ownerName,
    // contentOwnerEmail: props.ownerEmail,
    contentSharing: props.share,
    contentTitle: props.title,
    contentTotalComments: props.totalComments,
    contentTotalLikes: props.totalLikes,
    contentType: props.contentType,
    contentUserAccessGroupIds,
    // contentUserAccessGroupNames: props.contentUserAccessGroupNames,
    contentListPosition: props.contentListPosition,
    contentLocation: props.contentLocation,
    contentSubLocation: props.contentSubLocation ? props.contentSubLocation : null,
    contentSessionsTimestamp: props.contentSessionsTimestamp ? props.contentSessionsTimestamp : null,
    audioSessionsTimestamp: props.audioSessionsTimestamp ? props.audioSessionsTimestamp : null,
    videoSessionsTimestamp: props.videoSessionsTimestamp ? props.videoSessionsTimestamp : null,
    contentSessionId: props.contentSessionId ? props.contentSessionId : null,
    audioSessionId: props.audioSessionId ? props.audioSessionId : null,
    videoSessionId: props.videoSessionId ? props.videoSessionId : null,
    status: props.status ? props.status : statusHelper.SUCCESS
  }

  if (props.events) {
    objectToReturn.events = props.events
  }

  if (props.event) {
    objectToReturn.event = props.event
  }

  if (props.linkUrl) {
    objectToReturn.linkUrl = props.linkUrl
  }

  return objectToReturn
}

export const trackRecentContentWidget = async (properties, client, type) => {
  const recentContentEvent = type || eventTypes.MY_RECENT_CONTENT_WIDGET
  await trackEventsAndUserActivity(recentContentEvent, properties, client)
}

export const trackProfileWidget = async (client, type) => {
  // const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(type, {}, client)
}

export const trackContentIteration = async (properties, client, type) => {
  await trackEventsAndUserActivity(type, properties, client)
}

export const trackUserNameUpdate = async (data, properties) => {
  properties.status = data.status
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes.USER_NAME_UPDATE, allDefaultProperties)
}

export const trackAlertOpenedEvent = async (properties) => {
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes.ALERT_OPENED, allDefaultProperties)
}

export const trackSearchEvent = async (properties, client) => {
  await trackEventsAndUserActivity(eventTypes.SEARCH_INITIATED, properties, client)
}

export const trackClickQuickLinkEvent = (properties, client) => {
  trackEventsAndUserActivity(eventTypes.QUICK_LINK_CLICKED, properties, client)
}

export const trackSearchItemClickedEvent = async (properties, client) => {
  await trackEventsAndUserActivity(eventTypes.SEARCH_ITEM_CLICKED, properties, client)
}

export const trackBannerOpened = async (props, contentLocation) => {
  const properties = Object.assign({}, props)
  properties.contentLocation = contentLocation
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes.BANNER_OPENED, allDefaultProperties)
}

export const trackLikeEvent = async (isLike, properties, client) => {
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  if (isLike) {
    await trackEventsAndUserActivity(eventTypes.CONTENT_LIKED, allDefaultProperties, client)
  } else {
    await trackEventsAndUserActivity(eventTypes.CONTENT_UNLIKE, allDefaultProperties, client)
  }
}

export const trackContentSession = async (properties, client) => {
  const currentContent = readFromCache(client, ['analytics']).analytics.currentContent || properties.currentContent
  const contentSessionId = readFromCache(client, ['analytics']).analytics.contentSessionId || Cookies.get('contentSessionId')
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes.CONTENT_SESSION, { ...allDefaultProperties, currentContent, contentSessionId })
}

export const trackVideoSession = async (properties, client, fromAppLoad) => {
  const videoSessionId = Cookies.get('videoSessionId')
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes.VIDEO_SESSION, { ...allDefaultProperties, videoSessionId })
}

export const trackAudioSession = async (properties, client, fromAppLoad) => {
  const audioSessionId = Cookies.get('audioSessionId') || null
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes.AUDIO_SESSION, { ...allDefaultProperties, audioSessionId })
}

export const trackContentOpened = async (properties, client) => {
  let currentContent
  let contentSessionId
  currentContent = readFromCache(client, ['analytics']).analytics.currentContent
  contentSessionId = readFromCache(client, ['analytics']).analytics.contentSessionId

  if (properties._id !== currentContent) {
    currentContent = properties._id
    contentSessionId = uuidv4()
  }

  if (!contentSessionId) contentSessionId = uuidv4()
  Cookies.set('contentSessionId', contentSessionId, { expires: 1 })
  Cookies.set('currentContent', currentContent, { expires: 1 })
  Cookies.set('contentSessionsTimestamp', new Date(), { expires: 1 })
  Cookies.set('activeSession', true, { expires: 1 })
  Cookies.set('properties', JSON.stringify(properties), { expires: 1 })
  writeToCache(apolloClient, { analytics: { ...cacheDefaults.analytics, currentContent, contentSessionId } })

  const allDefaultProperties = await getAllAnalyticProperties({ ...properties, contentSessionId })
  await trackEventsAndUserActivity(eventTypes.CONTENT_OPENED, allDefaultProperties, client)
  await trackContentSession(allDefaultProperties, client)
}

export const trackContentShared = async (properties, shareType, client) => {
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes.CONTENT_SHARED, { ...allDefaultProperties, shareType }, client)
}

export const trackVideoEvent = async (videoEvent, properties, client) => {
  let videoSessionId = readFromCache(client, ['analytics']).analytics.videoSessionId || uuidv4()
  let currentContent = readFromCache(client, ['analytics']).analytics.currentContent
  const analyticsCache = readFromCache(client, ['analytics']).analytics

  if ((properties._id !== currentContent) && (videoEvent === 'VIDEO_PLAYED')) {
    currentContent = properties._id
    videoSessionId = uuidv4()
    Cookies.set('videoSessionId', videoSessionId, { expires: 1 })
    Cookies.set('currentContent', currentContent, { expires: 1 })
    Cookies.set('videoSessionsTimestamp', new Date(), { expires: 1 })
    Cookies.set('activeVideoSession', true, { expires: 1 })
    Cookies.set('properties', JSON.stringify(properties), { expires: 1 })
  }

  writeToCache(apolloClient, { analytics: { ...analyticsCache, currentContent, videoSessionId } })

  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes[`${videoEvent}`], allDefaultProperties)
  await trackVideoSession(properties, client)
}

export const trackAudioEvent = async (audioEvent, properties, client) => {
  let audioSessionId = readFromCache(client, ['analytics']).analytics.audioSessionId || uuidv4()
  let currentContent = readFromCache(client, ['analytics']).analytics.currentContent
  const analyticsCache = readFromCache(client, ['analytics']).analytics
  if (!audioSessionId) audioSessionId = uuidv4()

  if ((properties._id !== currentContent) && (audioEvent === 'AUDIO_PLAYED')) {
    currentContent = properties._id
    audioSessionId = uuidv4()
    Cookies.set('audioSessionId', audioSessionId, { expires: 1 })
    Cookies.set('currentContent', currentContent, { expires: 1 })
    Cookies.set('audioSessionsTimestamp', new Date(), { expires: 1 })
    Cookies.set('activeAudioSession', true, { expires: 1 })
    Cookies.set('properties', JSON.stringify(properties), { expires: 1 })
  }

  writeToCache(apolloClient, { analytics: { ...analyticsCache, currentContent, audioSessionId } })
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes[`${audioEvent}`], allDefaultProperties)
  await trackAudioSession(properties, client)
}

export const trackAppOpenEvent = async (appInfoProps) => {
  await trackEventsAndUserActivity(eventTypes.APP_INTEGRATION_OPEN, { appInfoProps })
}

export const trackContentSaved = async (properties, client) => {
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes.CONTENT_SAVED_OFFLINE, allDefaultProperties, client)
}

export const trackCommentEvent = async (commentEvent, properties, additionalCommentProps, client) => {
  const allDefaultProperties = await getAllAnalyticProperties(properties)
  await trackEventsAndUserActivity(eventTypes[`${commentEvent}`], { ...allDefaultProperties, ...additionalCommentProps }, client)
}

export const trackFolderOpenEvent = async (file) => {
  await trackEventsAndUserActivity(eventTypes.FOLDER_OPENED, getFolderPropertiesToAnalytics(file))
}

export const trackSeeAllFoldersEvent = async (totalItems) => {
  await trackEventsAndUserActivity(eventTypes.SEE_ALL_FOLDERS, { totalItems })
}
export const trackLoadMoreFoldersEvent = async (totalItems) => {
  await trackEventsAndUserActivity(eventTypes.LOAD_MORE_FOLDERS, { totalItems })
}
export const trackSortFoldersEvent = async (column) => {
  await trackEventsAndUserActivity(eventTypes.SORT_FOLDERS, { column })
}
export const trackSeeAllContentsEvent = async (totalItems) => {
  await trackEventsAndUserActivity(eventTypes.SEE_ALL_CONTENTS, { totalItems })
}
export const trackLoadMoreContentsEvent = async (totalItems) => {
  await trackEventsAndUserActivity(eventTypes.LOAD_MORE_CONTENTS, { totalItems })
}
export const trackSortContentsEvent = async (column) => {
  await trackEventsAndUserActivity(eventTypes.SORT_CONTENTS, { column })
}

export const trackDirectoryWidgetEvent = async (directoryProps) => {
  await trackEventsAndUserActivity(eventTypes.MY_DIRECTORY_WIDGET, { directoryProps })
}

export const cookieClear = async () => {
  Cookies.remove('videoSessionId')
  Cookies.remove('activeVideoSession')
  Cookies.remove('videoProps')
  Cookies.remove('videoTimestamp')
  Cookies.remove('videoSessionsTimestamp')
  Cookies.remove('audioSessionId')
  Cookies.remove('activeAudioSession')
  Cookies.remove('audioProps')
  Cookies.remove('audioTimestamp')
  Cookies.remove('audioSessionsTimestamp')
  Cookies.remove('contentSessionId')
  Cookies.remove('currentContent')
  Cookies.remove('contentSessionsTimestamp')
  Cookies.remove('activeSession')
  Cookies.remove('properties')
}

export const trackContentSessionFromCookie = async (client) => {
  const props = JSON.parse(Cookies.get('properties'))
  const contentSessionId = Cookies.get('contentSessionId')
  const contentSessionsTimestamp = new Date(Cookies.get('contentSessionsTimestamp')).toISOString()
  trackContentSession({ ...props, contentSessionsTimestamp, contentSessionId }, client)
}

export const trackVideoSessionFromCookie = async (client) => {
  const props = JSON.parse(Cookies.get('videoProps'))
  const videoSessionId = Cookies.get('videoSessionId')
  const videoSessionsTimestamp = new Date(Cookies.get('videoSessionsTimestamp')).toISOString()
  trackVideoSession({ ...props, videoSessionsTimestamp, videoSessionId }, client)
}

export const trackAudioSessionFromCookie = async (client) => {
  const props = JSON.parse(Cookies.get('audioProps'))
  const audioSessionId = Cookies.get('audioSessionId')
  const audioSessionsTimestamp = new Date(Cookies.get('audioSessionsTimestamp')).toISOString()
  trackAudioSession({ ...props, audioSessionsTimestamp, audioSessionId }, client)
}

export const trackOpenAddressEvent = async (properties) => {
  await trackEventsAndUserActivity(eventTypes.ADDRESS_OPENED, properties)
}

export const cookieTimer = () => {
  const audioSessionsTimestamp = (new Date(Cookies.get('audioSessionsTimestamp')) && (Cookies.get('activeAudioSession') === 'true')) && new Date(Cookies.get('audioSessionsTimestamp')).getTime()
  audioSessionsTimestamp && Cookies.set('audioSessionsTimestamp', new Date(audioSessionsTimestamp + 10000))
  const videoSessionsTimestamp = (new Date(Cookies.get('videoSessionsTimestamp')) && (Cookies.get('activeVideoSession') === 'true')) && new Date(Cookies.get('videoSessionsTimestamp')).getTime()
  videoSessionsTimestamp && Cookies.set('videoSessionsTimestamp', new Date(videoSessionsTimestamp + 10000))
  const sessionsTimestamp = (new Date(Cookies.get('contentSessionsTimestamp')) && (Cookies.get('activeSession') === 'true')) && new Date(Cookies.get('contentSessionsTimestamp')).getTime()
  sessionsTimestamp && Cookies.set('contentSessionsTimestamp', new Date(sessionsTimestamp + 10000))
}

export const onLoad = (client, me, view) => {
  trackEventsAndUserActivity(view, {
    user: me._id,
    account: me.memberships[0].account._id,
    memberships: me.memberships
  })

  if (Cookies.get('activeSession') === 'true') {
    trackContentSessionFromCookie(client)
  }

  if (Cookies.get('activeVideoSession') === 'true') {
    trackVideoSessionFromCookie(client)
  }

  if (Cookies.get('activeAudioSession') === 'true') {
    trackAudioSessionFromCookie(client)
  }

  cookieClear()
}

const getFolderPropertiesToAnalytics = (folder) => ({
  contentFolderId: folder._id,
  contentFolderParentId: folder.parentId,
  contentFolderName: folder.title,
  contentFolderLocation: folder.location ? folder.location : 'Explore'
})
/**
 * @param {boolean} isUpload      True for upload, false for removing profile photo
 * @param {object}  properties    The properties object to pass in segment
 */
export const trackProfilePhotoEvent = async (isUpload = true, properties = {}) => {
  await trackEventsAndUserActivity(isUpload ? eventTypes.PROFILE_PHOTO_UPLOAD : eventTypes.PROFILE_PHOTO_DELETE, properties)
}
